import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SettingsValuesService {

  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get FootPrintAPI() {
    return this._SettingsService['FootPrintAPI'];
  }
  public get MongoDB() {
    return this._SettingsService['MongoDB'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Invoices() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get FootPrintApiManager() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Attachments() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Instructions() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Owners() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get PurchaseOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Notifications() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Materials() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get SalesOrders() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get Inventory() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
}
