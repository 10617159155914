import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';

import { app_TPM_invoicing_create_transactionsService } from './app.flow.index';
import { app_TPM_invoicing_mainService } from './app.flow.index';
import { app_TPM_invoicing_set_minimumService } from './app.flow.index';
import { app_TPM_invoicing_update_billing_recordsService } from './app.flow.index';
import { app_capture_metal_spot_prices_flowService } from './app.flow.index';
import { app_create_metal_spot_price_flowService } from './app.flow.index';
import { app_dailyHoldingsReportService } from './app.flow.index';
import { app_get_customer_invoiceService } from './app.flow.index';
import { app_get_holdingsService } from './app.flow.index';
import { app_get_metal_spot_priceService } from './app.flow.index';
import { app_get_metal_spot_price_by_date_rangeService } from './app.flow.index';
import { app_get_project_holdings_by_owner_idService } from './app.flow.index';
import { app_populate_metal_spot_priceService } from './app.flow.index';

import { $types } from './app.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Invoices: Invoices_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _TPM_invoicing_create_transactions: app_TPM_invoicing_create_transactionsService;
  public async TPM_invoicing_create_transactions(inParams: { projectId: number, cutOffDate?: string, billingContractId?: number, projectIdsToInclude?: number[], projectIdsToExclude?: number[], billingAggregationStrategyIds?: number[], termId?: number, taxScheduleId?: number, oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, useExistingInvoice?: boolean, instruction?: $types.Invoices.Instruction, isIRA: boolean }): Promise< { messages?: any[] }> {
    if(!this._TPM_invoicing_create_transactions) {
      this._TPM_invoicing_create_transactions = this.injector.get(app_TPM_invoicing_create_transactionsService);
    }
    return this._TPM_invoicing_create_transactions.run(inParams);
  }
  private _TPM_invoicing_main: app_TPM_invoicing_mainService;
  public async TPM_invoicing_main(inParams: { instructionId?: string, instructionName?: string, output?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[], isIRA: boolean }): Promise< { messages?: any[] }> {
    if(!this._TPM_invoicing_main) {
      this._TPM_invoicing_main = this.injector.get(app_TPM_invoicing_mainService);
    }
    return this._TPM_invoicing_main.run(inParams);
  }
  private _TPM_invoicing_set_minimum: app_TPM_invoicing_set_minimumService;
  public async TPM_invoicing_set_minimum(inParams: { transaction_id?: string, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[] }): Promise< { messages?: any[] }> {
    if(!this._TPM_invoicing_set_minimum) {
      this._TPM_invoicing_set_minimum = this.injector.get(app_TPM_invoicing_set_minimumService);
    }
    return this._TPM_invoicing_set_minimum.run(inParams);
  }
  private _TPM_invoicing_update_billing_records: app_TPM_invoicing_update_billing_recordsService;
  public async TPM_invoicing_update_billing_records(inParams: { wait?: boolean, run?: boolean, transaction_id?: string, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[] }): Promise< { output?: any[], billing_tasks?: { Id?: number, Amount?: number, ApplicableRate?: number }[] }> {
    if(!this._TPM_invoicing_update_billing_records) {
      this._TPM_invoicing_update_billing_records = this.injector.get(app_TPM_invoicing_update_billing_recordsService);
    }
    return this._TPM_invoicing_update_billing_records.run(inParams);
  }
  private _capture_metal_spot_prices_flow: app_capture_metal_spot_prices_flowService;
  public async capture_metal_spot_prices_flow(inParams: {  }): Promise< { results?: any }> {
    if(!this._capture_metal_spot_prices_flow) {
      this._capture_metal_spot_prices_flow = this.injector.get(app_capture_metal_spot_prices_flowService);
    }
    return this._capture_metal_spot_prices_flow.run(inParams);
  }
  private _create_metal_spot_price_flow: app_create_metal_spot_price_flowService;
  public async create_metal_spot_price_flow(inParams: { goldPrice: number, silverPrice: number, platinumPrice: number, palladiumPrice: number }): Promise< { reasons?: string[] }> {
    if(!this._create_metal_spot_price_flow) {
      this._create_metal_spot_price_flow = this.injector.get(app_create_metal_spot_price_flowService);
    }
    return this._create_metal_spot_price_flow.run(inParams);
  }
  private _dailyHoldingsReport: app_dailyHoldingsReportService;
  public async dailyHoldingsReport(inParams: { fromDate: string, toDate: string, accountNumber: string }): Promise< { results?: any }> {
    if(!this._dailyHoldingsReport) {
      this._dailyHoldingsReport = this.injector.get(app_dailyHoldingsReportService);
    }
    return this._dailyHoldingsReport.run(inParams);
  }
  private _get_customer_invoice: app_get_customer_invoiceService;
  public async get_customer_invoice(inParams: {  }): Promise< { results?: any }> {
    if(!this._get_customer_invoice) {
      this._get_customer_invoice = this.injector.get(app_get_customer_invoiceService);
    }
    return this._get_customer_invoice.run(inParams);
  }
  private _get_holdings: app_get_holdingsService;
  public async get_holdings(inParams: { email: string }): Promise< { results?: any }> {
    if(!this._get_holdings) {
      this._get_holdings = this.injector.get(app_get_holdingsService);
    }
    return this._get_holdings.run(inParams);
  }
  private _get_metal_spot_price: app_get_metal_spot_priceService;
  public async get_metal_spot_price(inParams: { transaction_id?: string }): Promise< { results?: { goldPrice?: number, silverPrice?: number, platinumPrice?: number, palladiumPrice?: number, captureDate?: string }, messages?: any[] }> {
    if(!this._get_metal_spot_price) {
      this._get_metal_spot_price = this.injector.get(app_get_metal_spot_priceService);
    }
    return this._get_metal_spot_price.run(inParams);
  }
  private _get_metal_spot_price_by_date_range: app_get_metal_spot_price_by_date_rangeService;
  public async get_metal_spot_price_by_date_range(inParams: { transaction_id?: string, date_from?: string, date_to?: string }): Promise< { results?: { goldPrice?: number, silverPrice?: number, platinumPrice?: number, palladiumPrice?: number, captureDate?: string, dateNumber?: number, month?: number, year?: number, day?: number }[], messages?: any[] }> {
    if(!this._get_metal_spot_price_by_date_range) {
      this._get_metal_spot_price_by_date_range = this.injector.get(app_get_metal_spot_price_by_date_rangeService);
    }
    return this._get_metal_spot_price_by_date_range.run(inParams);
  }
  private _get_project_holdings_by_owner_id: app_get_project_holdings_by_owner_idService;
  public async get_project_holdings_by_owner_id(inParams: { owner_id: number, project_ids?: number[], page_size?: number, page?: number }): Promise< { results?: any }> {
    if(!this._get_project_holdings_by_owner_id) {
      this._get_project_holdings_by_owner_id = this.injector.get(app_get_project_holdings_by_owner_idService);
    }
    return this._get_project_holdings_by_owner_id.run(inParams);
  }
  private _populate_metal_spot_price: app_populate_metal_spot_priceService;
  public async populate_metal_spot_price(inParams: {  }): Promise< { messages?: any[] }> {
    if(!this._populate_metal_spot_price) {
      this._populate_metal_spot_price = this.injector.get(app_populate_metal_spot_priceService);
    }
    return this._populate_metal_spot_price.run(inParams);
  }
}
