import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './app.types'

@Injectable({ providedIn: 'root' })
export class app_get_metal_spot_priceService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { transaction_id?: string }): Promise<{ results?: { goldPrice?: number, silverPrice?: number, platinumPrice?: number, palladiumPrice?: number, captureDate?: string }, messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/app/functions/get_metal_spot_price`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

