import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './app.types'

@Injectable({ providedIn: 'root' })
export class app_dailyHoldingsReportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { fromDate: string, toDate: string, accountNumber: string }): Promise<{ results?: any }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.fromDate)) {
      missingRequiredInParams.push('\'fromDate\'');
    }
    if (isNil(inParams.toDate)) {
      missingRequiredInParams.push('\'toDate\'');
    }
    if (isNil(inParams.accountNumber)) {
      missingRequiredInParams.push('\'accountNumber\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/functions/dailyHoldingsReport`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

