import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild
} from '@angular/core';

import { DropdownSingleComponent } from './components/dropdown-single.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-home',
  templateUrl: './app.home.component.html'
})
export class app_homeComponent implements OnInit {

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  toolbar = {
  };


  fieldsets = {
  };

  @ViewChild('dd_config') dd_config: DropdownSingleComponent;
  @ViewChild('dd_method') dd_method: DropdownSingleComponent;

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) { 

  }

  ngOnInit(): void {
    this.$init();
  
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
    this.initialized = true;
  }

  close() {
    this.$finish.emit();
  }

  typeValue = null;
  typeList = [
    {
      key: 6,
      name: 'Datasource'
    },
    {
      key: 9,
      name: 'Function'
    }
  ];

  async typeDisplayWithFn(value: any): Promise<string> {
    return this.typeList.find(item => item.key === value)?.name;
  }

  async typeOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (this.utils.isDefinedTrimmed(filterText)) {
      return { list: this.typeList.filter(item => item.name.startsWith(filterText)), totalCount: this.typeList.length };
    } else {
      return { list: this.typeList, totalCount: this.typeList.length };
    }
  }

  typeValueChange(value) {
    this.typeValue = value;
    this.configValueChange(null);
    this.dd_config.clearListData();
  }


  configValue = null;
  config = null;
  configList = [
    {
      key: 'TPM_invoicing_create_transactions',
      name: 'TPM_invoicing_create_transactions',
      referenceName: 'TPM_invoicing_create_transactions',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'cutOffDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'billingContractId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'billingAggregationStrategyIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'termId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taxScheduleId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'oneInvoicePerShipment',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'oneInvoicePerWarehouse',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'useExistingInvoice',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'instruction',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'TPM_invoicing_main',
      name: 'TPM_invoicing_main',
      referenceName: 'TPM_invoicing_main',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'instructionId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'instructionName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'output',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'TPM_invoicing_set_minimum',
      name: 'TPM_invoicing_set_minimum',
      referenceName: 'TPM_invoicing_set_minimum',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'TPM_invoicing_update_billing_records',
      name: 'TPM_invoicing_update_billing_records',
      referenceName: 'TPM_invoicing_update_billing_records',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'wait',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'run',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'capture_metal_spot_prices_flow',
      name: 'capture_metal_spot_prices_flow',
      referenceName: 'capture_metal_spot_prices_flow',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'create_metal_spot_price_flow',
      name: 'create_metal_spot_price_flow',
      referenceName: 'create_metal_spot_price_flow',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'goldPrice',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'silverPrice',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'platinumPrice',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'palladiumPrice',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'dailyHoldingsReport',
      name: 'dailyHoldingsReport',
      referenceName: 'dailyHoldingsReport',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'fromDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'toDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'accountNumber',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_customer_invoice',
      name: 'get_customer_invoice',
      referenceName: 'get_customer_invoice',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'get_holdings',
      name: 'get_holdings',
      referenceName: 'get_holdings',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'email',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_metal_spot_price',
      name: 'get_metal_spot_price',
      referenceName: 'get_metal_spot_price',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_metal_spot_price_by_date_range',
      name: 'get_metal_spot_price_by_date_range',
      referenceName: 'get_metal_spot_price_by_date_range',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_project_holdings_by_owner_id',
      name: 'get_project_holdings_by_owner_id',
      referenceName: 'get_project_holdings_by_owner_id',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'owner_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'project_ids',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'page_size',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'page',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'populate_metal_spot_price',
      name: 'populate_metal_spot_price',
      referenceName: 'populate_metal_spot_price',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'TPM_ds_get_billing_codes',
      name: 'TPM_ds_get_billing_codes',
      referenceName: 'TPM_ds_get_billing_codes',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'TPM_ds_get_billing_contract_line_details',
      name: 'TPM_ds_get_billing_contract_line_details',
      referenceName: 'TPM_ds_get_billing_contract_line_details',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'strategy_field_names',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'strategy_field_names',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'strategy_field_names',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'TPM_ds_get_billing_rates',
      name: 'TPM_ds_get_billing_rates',
      referenceName: 'TPM_ds_get_billing_rates',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'billing_contract_line_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'TPM_ds_get_billing_tasks',
      name: 'TPM_ds_get_billing_tasks',
      referenceName: 'TPM_ds_get_billing_tasks',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'hasNotes',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'hasNotes',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'hasNotes',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'TPM_ds_get_billing_tasks_projects',
      name: 'TPM_ds_get_billing_tasks_projects',
      referenceName: 'TPM_ds_get_billing_tasks_projects',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'TPM_ds_get_invoice_totals',
      name: 'TPM_ds_get_invoice_totals',
      referenceName: 'TPM_ds_get_invoice_totals',
      appReferenceName: 'app',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'isIRA',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projectIdsToInclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'projectIdsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'alerts_for_entity_import_insert',
      name: 'alerts_for_entity_import_insert' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_for_entity_import_insert',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'request_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_for_entity_import_main',
      name: 'alerts_for_entity_import_main' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_for_entity_import_main',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'days',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_main',
      name: 'alerts_main' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_main',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'days',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'retry',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_send',
      name: 'alerts_send' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_send',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'log_level',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'log_input',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'log_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'alerts_send_test',
      name: 'alerts_send_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'alerts_send_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'to',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_asn_order_flow',
      name: 'cancel_asn_order_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_order_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'status_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_asn_order_lines',
      name: 'cancel_asn_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_asn_orders',
      name: 'cancel_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_order_by_id',
      name: 'cancel_order_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_order_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_notes',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'order_type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_class_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'order_status_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'validate',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_purchase_order_lines',
      name: 'cancel_purchase_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_purchase_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_purchase_orders',
      name: 'cancel_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_sales_order_lines',
      name: 'cancel_sales_order_lines' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_sales_order_lines',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_lines',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'cancel_sales_orders',
      name: 'cancel_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'cancel_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_accounts',
      name: 'create_accounts' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_accounts',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'accounts',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_asn_orders',
      name: 'create_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_carriers',
      name: 'create_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'carriers',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_instruction',
      name: 'create_instruction' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_instruction',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'instruction',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_material_packagings',
      name: 'create_material_packagings' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_material_packagings',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_packagings',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_materials',
      name: 'create_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_missing_shipments',
      name: 'create_missing_shipments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_missing_shipments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_purchase_orders',
      name: 'create_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_sales_orders',
      name: 'create_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'create_vendor_lots',
      name: 'create_vendor_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'create_vendor_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendor_lots',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'entity_import_parse_errors',
      name: 'entity_import_parse_errors' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'entity_import_parse_errors',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'request_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_move',
      name: 'file_transfer_move' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_move',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extenstion',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'source_folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'target_folder',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_pull',
      name: 'file_transfer_pull' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_pull',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extenstion',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'download',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'file_transfer_push',
      name: 'file_transfer_push' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_push',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'service_endpoint',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'container_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'folder',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'authentication',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'files',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'file_transfer_test',
      name: 'file_transfer_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'file_transfer_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'find_and_cancel_orders',
      name: 'find_and_cancel_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'find_and_cancel_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_class',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner_reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendor_reference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'order_notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_age_days',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footpring_generic_json_order_to_xml_order',
      name: 'footpring_generic_json_order_to_xml_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footpring_generic_json_order_to_xml_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'DatexOrderInfo',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_check_order_status',
      name: 'footprint_check_order_status' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_check_order_status',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'update',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_confirm_entity_import',
      name: 'footprint_confirm_entity_import' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_confirm_entity_import',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_export_data',
      name: 'footprint_generic_json_adjustment_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'renderLots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'renderLicensePlates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'renderSerialNumbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'hasTransmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taskIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'typeIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_export_flow',
      name: 'footprint_generic_json_adjustment_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modified_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modified_to',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'has_transmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'output_messages',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_import_flow',
      name: 'footprint_generic_json_adjustment_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'adjustments',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'submitAsync',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'environment',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sender',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'receiver',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_sample',
      name: 'footprint_generic_json_adjustment_sample' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_sample',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'footprint_generic_json_adjustment_schema',
      name: 'footprint_generic_json_adjustment_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_adjustment_to_entity_import_flow',
      name: 'footprint_generic_json_adjustment_to_entity_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_adjustment_to_entity_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'adjustment',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_export_data',
      name: 'footprint_generic_json_inventory_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'only_inventory',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_export_flow',
      name: 'footprint_generic_json_inventory_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'render_lots',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_license_plates',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'render_serial_numbers',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_inventory_schema',
      name: 'footprint_generic_json_inventory_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_inventory_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_export_data',
      name: 'footprint_generic_json_invoice_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'invoice_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_export_flow',
      name: 'footprint_generic_json_invoice_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'invoice_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'invoice_lookup',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_invoice_schema',
      name: 'footprint_generic_json_invoice_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_invoice_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'map',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_material_import_flow',
      name: 'footprint_generic_json_material_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_material_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'materials',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_material_to_entity_import_xml_flow',
      name: 'footprint_generic_json_material_to_entity_import_xml_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_material_to_entity_import_xml_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_export_data',
      name: 'footprint_generic_json_order_export_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_export_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_export_flow',
      name: 'footprint_generic_json_order_export_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_export_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_lookup',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_import_flow',
      name: 'footprint_generic_json_order_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'orders',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ingress_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'submitAsync',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'environment',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sender',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'receiver',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_schema',
      name: 'footprint_generic_json_order_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'map',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_summary_data',
      name: 'footprint_generic_json_order_summary_data' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_summary_data',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'shipment_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_to_crud_batch_request_flow',
      name: 'footprint_generic_json_order_to_crud_batch_request_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_to_crud_batch_request_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_order_to_entity_import_flow',
      name: 'footprint_generic_json_order_to_entity_import_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_order_to_entity_import_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'source_platform',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'order_action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'account_action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_json_shipment_contents',
      name: 'footprint_generic_json_shipment_contents' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_json_shipment_contents',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'shipmentId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'orderId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'typeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_generic_serial_numbers',
      name: 'footprint_generic_serial_numbers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_generic_serial_numbers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'project_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material_lookup_code',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'packaged_amount',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'packaging_short_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'net_weight',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'gross_weight',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'weight_uom',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_json_entity_import_entity_schema',
      name: 'footprint_json_entity_import_entity_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_json_entity_import_entity_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'example',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'footprint_manage_wavelength_transmissions',
      name: 'footprint_manage_wavelength_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'footprint_manage_wavelength_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_direction',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'type_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_accounts',
      name: 'get_accounts' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_accounts',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_asn_orders',
      name: 'get_asn_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_asn_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_carriers',
      name: 'get_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_configuration',
      name: 'get_configuration' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_configuration',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'value',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'user_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypt',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_configuration_examples',
      name: 'get_configuration_examples' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_configuration_examples',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_date_from_footprint',
      name: 'get_date_from_footprint' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_date_from_footprint',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'get_inventory',
      name: 'get_inventory' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_inventory',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'aggregation_level',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_inventory_adjustments',
      name: 'get_inventory_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_inventory_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'get_materials',
      name: 'get_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_purchase_orders',
      name: 'get_purchase_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_purchase_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_reason_codes',
      name: 'get_reason_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_reason_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_report_data_flow',
      name: 'get_report_data_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_report_data_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'package_name',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'report_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'inputs',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_sales_orders',
      name: 'get_sales_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_sales_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_user_defined_field_names',
      name: 'get_user_defined_field_names' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_user_defined_field_names',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'EntityTypes',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_user_defined_field_values',
      name: 'get_user_defined_field_values' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_user_defined_field_values',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'UserDefinedFieldNames',
            type: 'string',
            isCollection: true
          },
          {
            required: false,
            id: 'EntityType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'EntityKeys',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'get_vendor_lots',
      name: 'get_vendor_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'get_vendor_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'filters',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'insert_log',
      name: 'insert_log' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'insert_log',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'level',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'process',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'integrity_check_interface_subsets',
      name: 'integrity_check_interface_subsets' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'integrity_check_interface_subsets',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input_create_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_material_packaging',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_material',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_material_packaging',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_sales_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_sales_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_purchase_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_purchase_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_asn_order',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_cancel_asn_order_line',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_carrier',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_account',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_account',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_inventory',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_get_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_update_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_vendor_lot',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'input_create_instruction',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'inventory_adjustment_query',
      name: 'inventory_adjustment_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'inventory_adjustment_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'hasTransmissions',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'summary',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'inventory_snapshot_query',
      name: 'inventory_snapshot_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'inventory_snapshot_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'material',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_asn_order_cancelable_flow',
      name: 'is_asn_order_cancelable_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'is_asn_order_cancelable_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'status_id',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'lifecycle_clean',
      name: 'lifecycle_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'lifecycle_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_integrations',
      name: 'list_integrations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_integrations',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_processes',
      name: 'list_processes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_processes',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'process',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_statuses',
      name: 'list_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_transaction_types',
      name: 'list_transaction_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'list_transaction_types',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_clean',
      name: 'logs_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'logs_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'levels_to_purge',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'lot_create',
      name: 'lot_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'lot_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendorLotId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'description',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'main_auto_email_execute_rules_flow',
      name: 'main_auto_email_execute_rules_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'main_auto_email_execute_rules_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'messages_clean',
      name: 'messages_clean' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'messages_clean',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'integration_names',
            type: 'string',
            isCollection: true
          },
          {
            required: true,
            id: 'days_to_retain',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'additional_criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'migrate_storage_to_utilities',
      name: 'migrate_storage_to_utilities' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'migrate_storage_to_utilities',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'owner_create',
      name: 'owner_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'owner_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'print_cloud_report',
      name: 'print_cloud_report' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'print_cloud_report',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'packageName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportName',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'reportInputs',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'printerName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'purchase_order_create',
      name: 'purchase_order_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'purchase_order_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'requestedDeliveryDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderLines',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'addresses',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'customFields',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'carrier',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'purchase_order_query',
      name: 'purchase_order_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'purchase_order_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_create',
      name: 'sales_order_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'requestedDeliveryDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'orderLines',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'addresses',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'customFields',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'carrier',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'carrierServiceType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_query',
      name: 'sales_order_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'modifiedTo',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'trackQueriedOrders',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'paging',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'sales_order_shipping_summary_query',
      name: 'sales_order_shipping_summary_query' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sales_order_shipping_summary_query',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'owner',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'ownerReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'vendorReference',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'shippedFrom',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'shippedTo',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'sftp_pull',
      name: 'sftp_pull' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sftp_pull',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'connection',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'source_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'archive_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'error_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'file_extension',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'file_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'sort_asc',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'sftp_push',
      name: 'sftp_push' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'sftp_push',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'connection',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'target_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transit_path',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'files',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_adjustments',
      name: 'storage_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'task_ids',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_configurations',
      name: 'storage_configurations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_configurations',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_control',
      name: 'storage_control' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_control',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'option',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_lifecycle',
      name: 'storage_lifecycle' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_lifecycle',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'statuses',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'storage_logs',
      name: 'storage_logs' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_logs',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_messages',
      name: 'storage_messages' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_messages',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'integration_name',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_populate_date_number',
      name: 'storage_populate_date_number' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_populate_date_number',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
    },
    {
      key: 'storage_string_encrypt',
      name: 'storage_string_encrypt' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_string_encrypt',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypted',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_test',
      name: 'storage_test' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'storage_test',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'messages',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'submit_entity_import_json_request',
      name: 'submit_entity_import_json_request' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'submit_entity_import_json_request',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'entityImport',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'groupId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transactionGroupId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'submit_entity_import_xml_request',
      name: 'submit_entity_import_xml_request' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'submit_entity_import_xml_request',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'entityImportXml',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'groupId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transactionGroupId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'translate_material_from_footprint_to_public',
      name: 'translate_material_from_footprint_to_public' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_material_from_footprint_to_public',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'footprint_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'translate_material_from_public_to_footprint_flow',
      name: 'translate_material_from_public_to_footprint_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_material_from_public_to_footprint_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'public_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'translate_sales_order_from_public_to_footprint_flow',
      name: 'translate_sales_order_from_public_to_footprint_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'translate_sales_order_from_public_to_footprint_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'public_materials',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'unpick_licenseplate_flow',
      name: 'unpick_licenseplate_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'unpick_licenseplate_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'licenseplateId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_asn_order',
      name: 'update_asn_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_asn_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_carrier',
      name: 'update_carrier' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_carrier',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'carrier_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_last_wavelength_transmission',
      name: 'update_last_wavelength_transmission' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_last_wavelength_transmission',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_direction',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transmission_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_material',
      name: 'update_material' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_material',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_material_packaging',
      name: 'update_material_packaging' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_material_packaging',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'material_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'packaging',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_purchase_order',
      name: 'update_purchase_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_purchase_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_sales_order',
      name: 'update_sales_order' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_sales_order',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_vendor_lot',
      name: 'update_vendor_lot' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'update_vendor_lot',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'vendor_lot_id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'update',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_create_sales_order_request_flow',
      name: 'validate_create_sales_order_request_flow' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'validate_create_sales_order_request_flow',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'order',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'vendor_lot_create',
      name: 'vendor_lot_create' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'vendor_lot_create',
      appReferenceName: 'FootPrintApiManager',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'materialId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'description',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'expirationDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'manufactureDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'vat',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_base_order_editor',
      name: 'ds_base_order_editor' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_base_order_editor',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_base_order_lines_grid',
      name: 'ds_base_order_lines_grid' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_base_order_lines_grid',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'order_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_import_requests',
      name: 'ds_entity_import_requests' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_import_requests',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_import_requests_grid',
      name: 'ds_entity_import_requests_grid' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_import_requests_grid',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'statusCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'dateTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'projects',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleWarehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'singleProjectName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectsExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'warehousesExact',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dateType',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_user_defined_fields',
      name: 'ds_entity_user_defined_fields' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_entity_user_defined_fields',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'EntityType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_address',
      name: 'ds_find_address' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_address',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'Name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'LookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'AccountType',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'City',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Country',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Line1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'Line2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'PostalCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'State',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'AttentionOf',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'FirstName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'LastName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ReferenceCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ContactType',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_carriers',
      name: 'ds_find_carriers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_carriers',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'carriers',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_invoices',
      name: 'ds_find_invoices' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_invoices',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_licenseplates',
      name: 'ds_find_licenseplates' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_licenseplates',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'shippingContainerIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'typeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_lots',
      name: 'ds_find_lots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_lots',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_material_packagings',
      name: 'ds_find_material_packagings' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_material_packagings',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_materials',
      name: 'ds_find_materials' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_materials',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_operation_codes',
      name: 'ds_find_operation_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_operation_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_address_types',
      name: 'ds_find_order_address_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_address_types',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_by_transaction_id',
      name: 'ds_find_order_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_order_class',
      name: 'ds_find_order_class' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_order_class',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_orders',
      name: 'ds_find_orders' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_orders',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'vendorReference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shippedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'orderClass',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludeTransmitted',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_projects',
      name: 'ds_find_projects' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_projects',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_reason_codes',
      name: 'ds_find_reason_codes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_reason_codes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'parentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_serialnumbers',
      name: 'ds_find_serialnumbers' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_serialnumbers',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'archived',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'packagingId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'outboundShipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_shipment_transmission_by_transaction_id',
      name: 'ds_find_shipment_transmission_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_shipment_transmission_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_task_transmission_by_transaction_id',
      name: 'ds_find_task_transmission_by_transaction_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_task_transmission_by_transaction_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_tasks',
      name: 'ds_find_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'operationCodeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'chainHead',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualTargetLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'actualSourceLicensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_vendorlots',
      name: 'ds_find_vendorlots' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_vendorlots',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_warehouses',
      name: 'ds_find_warehouses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_find_warehouses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'searchProperties',
              type: 'object',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_generic_order_addresses_schema',
      name: 'ds_generic_order_addresses_schema' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_generic_order_addresses_schema',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_addresses_by_entity_and_id',
      name: 'ds_get_addresses_by_entity_and_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_addresses_by_entity_and_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'contactId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'addressId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_adjustment_tasks',
      name: 'ds_get_adjustment_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_adjustment_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'hasTransmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'modifiedTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'material',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'take',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_records_by_invoice_line_ids',
      name: 'ds_get_billing_records_by_invoice_line_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_billing_records_by_invoice_line_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_carrier_by_id',
      name: 'ds_get_carrier_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_carrier_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      name: 'ds_get_completed_receiving_tasks_by_orderId_top1' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_completed_receiving_tasks_by_orderId_top1',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_completed_shipments_without_transmissions',
      name: 'ds_get_completed_shipments_without_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_completed_shipments_without_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'project_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_class',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'shipped_date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipment_type',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'has_order_transmissions',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'transmission_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'order_completed',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_currency_by_id',
      name: 'ds_get_currency_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_currency_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse',
      name: 'ds_get_first_warehouse' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_first_warehouse',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_inventory_by_license_plate_lot_by_project_id',
      name: 'ds_get_inventory_by_license_plate_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_inventory_by_license_plate_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_inventory_by_location_lot_by_project_id',
      name: 'ds_get_inventory_by_location_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_inventory_by_location_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_by_invoice_id',
      name: 'ds_get_invoice_by_invoice_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_invoice_by_invoice_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_lines_by_invoice_id',
      name: 'ds_get_invoice_lines_by_invoice_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_invoice_lines_by_invoice_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'invoice_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_latest_order_by_project',
      name: 'ds_get_latest_order_by_project' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_latest_order_by_project',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'project_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_license_plates_for_adjustments',
      name: 'ds_get_license_plates_for_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_license_plates_for_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_licenseplate_by_licenseplateId',
      name: 'ds_get_licenseplate_by_licenseplateId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_licenseplate_by_licenseplateId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_locations_by_locationIds',
      name: 'ds_get_locations_by_locationIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_locations_by_locationIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_lots_by_lotIds',
      name: 'ds_get_lots_by_lotIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_lots_by_lotIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_by_lookupcode_and_project_lookupcode',
      name: 'ds_get_material_by_lookupcode_and_project_lookupcode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_material_by_lookupcode_and_project_lookupcode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'project_lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_material_packagings_by_upc',
      name: 'ds_get_material_packagings_by_upc' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_material_packagings_by_upc',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'upc',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_and_packages_by_project_id',
      name: 'ds_get_materials_and_packages_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_and_packages_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_by_materialCodes_projectId',
      name: 'ds_get_materials_by_materialCodes_projectId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_by_materialCodes_projectId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'materialCodes',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_materials_with_inventory',
      name: 'ds_get_materials_with_inventory' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_materials_with_inventory',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'material_lookup_code',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_measurement_units',
      name: 'ds_get_measurement_units' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_measurement_units',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_addresses_by_order_id',
      name: 'ds_get_order_addresses_by_order_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_addresses_by_order_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_licenseplate',
      name: 'ds_get_order_by_licenseplate' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_by_licenseplate',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'LicenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_information_by_shipment_id',
      name: 'ds_get_order_information_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_information_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_line_information_by_order_id',
      name: 'ds_get_order_line_information_by_order_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_line_information_by_order_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_status_by_transaction_ids',
      name: 'ds_get_order_status_by_transaction_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_order_status_by_transaction_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'transaction_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orderlines_by_orderId',
      name: 'ds_get_orderlines_by_orderId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orderlines_by_orderId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_by_ids',
      name: 'ds_get_orders_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orders_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_orders_without_shipments',
      name: 'ds_get_orders_without_shipments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_orders_without_shipments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_owner_by_ownerCode',
      name: 'ds_get_owner_by_ownerCode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_owner_by_ownerCode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'ownerCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_pallet_transactions_by_shipment_id',
      name: 'ds_get_pallet_transactions_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_pallet_transactions_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id',
      name: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_physical_inventory_by_license_plate_lot_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_project_and_owner_by_lookup_code',
      name: 'ds_get_project_and_owner_by_lookup_code' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_project_and_owner_by_lookup_code',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_projects_by_projectCode',
      name: 'ds_get_projects_by_projectCode' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_projects_by_projectCode',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectCode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_projects_by_project_and_owner',
      name: 'ds_get_projects_by_project_and_owner' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_projects_by_project_and_owner',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'owner',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'project',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_receiving_tasks_by_shipment_id',
      name: 'ds_get_receiving_tasks_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_receiving_tasks_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_request_xml_by_requestId_editor',
      name: 'ds_get_request_xml_by_requestId_editor' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_request_xml_by_requestId_editor',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_sample_data_for_adjustment',
      name: 'ds_get_sample_data_for_adjustment' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_sample_data_for_adjustment',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_ids',
      name: 'ds_get_serial_numbers_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_project_id',
      name: 'ds_get_serial_numbers_by_project_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_project_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialLookupCode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'licensePlateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lotId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_serial_numbers_by_shipment_id',
      name: 'ds_get_serial_numbers_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_serial_numbers_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipmentLines_by_orderId_shipmentId',
      name: 'ds_get_shipmentLines_by_orderId_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipmentLines_by_orderId_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipment_contents_by_shipment_id',
      name: 'ds_get_shipment_contents_by_shipment_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipment_contents_by_shipment_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_ids',
      name: 'ds_get_shipments_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_orderId',
      name: 'ds_get_shipments_by_orderId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_orderId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_shipments_by_shipmentId',
      name: 'ds_get_shipments_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_shipments_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_source_license_plate_tasks_by_shipmentId',
      name: 'ds_get_source_license_plate_tasks_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_source_license_plate_tasks_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_status_change_tasks',
      name: 'ds_get_status_change_tasks' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_status_change_tasks',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'lotIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'licensePlateIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'locationIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'taskIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'chainHeads',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'serialNumberIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: 'operationTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tags',
      name: 'ds_get_tags' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tags',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entity_type_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tasks_by_licenseplateId',
      name: 'ds_get_tasks_by_licenseplateId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tasks_by_licenseplateId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'licenseplateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_tax_schedule_by_id',
      name: 'ds_get_tax_schedule_by_id' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_tax_schedule_by_id',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_order_transmissions',
      name: 'ds_get_wavelength_order_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_order_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_shipment_transmissions',
      name: 'ds_get_wavelength_shipment_transmissions' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_shipment_transmissions',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_wavelength_transmission_type',
      name: 'ds_get_wavelength_transmission_type' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_wavelength_transmission_type',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'name',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_work_orders_by_ids',
      name: 'ds_get_work_orders_by_ids' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_get_work_orders_by_ids',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_material_statuses',
      name: 'ds_material_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_material_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_message_staging',
      name: 'ds_message_staging' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_message_staging',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_order_serial_tasks_by_shipmentId',
      name: 'ds_order_serial_tasks_by_shipmentId' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_order_serial_tasks_by_shipmentId',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'shipmentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_adjustments',
      name: 'ds_storage_adjustments' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_adjustments',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'task_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'task_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_configurations',
      name: 'ds_storage_configurations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_configurations',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_control',
      name: 'ds_storage_control' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_control',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'current',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'current',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_control_partners_widget',
      name: 'ds_storage_control_partners_widget' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_control_partners_widget',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_integrations',
      name: 'ds_storage_integrations' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_integrations',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'forDropDown',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'forDropDown',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_lifecycle',
      name: 'ds_storage_lifecycle' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_lifecycle',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statuses',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'statuses',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_logs',
      name: 'ds_storage_logs' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_logs',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'created_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'created_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_message_statuses_widget',
      name: 'ds_storage_message_statuses_widget' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_message_statuses_widget',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_messages',
      name: 'ds_storage_messages' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_messages',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modified_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'request_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'notes',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'sender',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'receiver',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ingress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'egress_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'modified_on',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'integration_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'request_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_processes',
      name: 'ds_storage_processes' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_processes',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_statuses',
      name: 'ds_storage_statuses' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_statuses',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_storage_transaction_types',
      name: 'ds_storage_transaction_types' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_storage_transaction_types',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_transaction_report',
      name: 'ds_transaction_report' + ' ('+ 'FootPrintApiManager' +')',
      referenceName: 'ds_transaction_report',
      appReferenceName: 'FootPrintApiManager',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'log_ids',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'message_ids',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'activate_billing_contract_flow',
      name: 'activate_billing_contract_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'activate_billing_contract_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'contractId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'are_billing_contract_dates_valid_flow',
      name: 'are_billing_contract_dates_valid_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'are_billing_contract_dates_valid_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'accountId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'startDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'endDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'contractId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'billing_rate_delete_flow',
      name: 'billing_rate_delete_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'billing_rate_delete_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'rateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'typeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'billing_rate_insert_or_update_flow',
      name: 'billing_rate_insert_or_update_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'billing_rate_insert_or_update_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'rateId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'rangeStart',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'rangeEnd',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'option',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'rate',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'minimumCharge',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'typeId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'cancel_billing_task_flow',
      name: 'cancel_billing_task_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'cancel_billing_task_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'cancelledReasonCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'cancelledNotes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'commodity_price',
      name: 'commodity_price' + ' ('+ 'Invoices' +')',
      referenceName: 'commodity_price',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'commodity_price_populate',
      name: 'commodity_price_populate' + ' ('+ 'Invoices' +')',
      referenceName: 'commodity_price_populate',
      appReferenceName: 'Invoices',
      type: 9,
    },
    {
      key: 'copy_billing_contract_flow',
      name: 'copy_billing_contract_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'copy_billing_contract_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'contractId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'startDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'endDate',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_billing_contract_flow',
      name: 'create_billing_contract_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_billing_contract_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_billing_contract_line_flow',
      name: 'create_billing_contract_line_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_billing_contract_line_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'billingAggregationStrategyId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_invoice_flow',
      name: 'create_invoice_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_invoice_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'typeId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'lookupcode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'invoiceDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'dueDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'invoiceTermId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'currencyId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_invoice_line_flow',
      name: 'create_invoice_line_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_invoice_line_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'billingCodeId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'quantity',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'unitPrice',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'minimumCharge',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'description',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_or_update_billing_contract_line_detail_flow',
      name: 'create_or_update_billing_contract_line_detail_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_or_update_billing_contract_line_detail_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'strategyName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'value',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'data',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'create_reasoncode_flow',
      name: 'create_reasoncode_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'create_reasoncode_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'properties',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'deactivate_billing_contract_flow',
      name: 'deactivate_billing_contract_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'deactivate_billing_contract_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'contractId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_billing_contract_flow',
      name: 'delete_billing_contract_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'delete_billing_contract_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'billingContractId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_billing_contract_line_flow',
      name: 'delete_billing_contract_line_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'delete_billing_contract_line_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_billing_task_flow',
      name: 'delete_billing_task_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'delete_billing_task_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_invoice_flow',
      name: 'delete_invoice_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'delete_invoice_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'delete_invoice_line_flow',
      name: 'delete_invoice_line_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'delete_invoice_line_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_confirm_requests',
      name: 'invoicing_confirm_requests' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_confirm_requests',
      appReferenceName: 'Invoices',
      type: 9,
    },
    {
      key: 'invoicing_create_invoice_lines',
      name: 'invoicing_create_invoice_lines' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_create_invoice_lines',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'output',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'billing_tasks',
            type: 'object',
            isCollection: true
          },
        ]
    },
    {
      key: 'invoicing_create_invoices',
      name: 'invoicing_create_invoices' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_create_invoices',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'instructionId',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'termId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'entities',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'useExistingInvoice',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_create_transactions',
      name: 'invoicing_create_transactions' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_create_transactions',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'projectId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'cutOffDate',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'billingContractId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'billingAggregationStrategyIds',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'termId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'taxScheduleId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'oneInvoicePerShipment',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'oneInvoicePerWarehouse',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'useExistingInvoice',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'instruction',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_instruction',
      name: 'invoicing_instruction' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_instruction',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_log',
      name: 'invoicing_log' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_log',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_main',
      name: 'invoicing_main' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_main',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'instructionId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'instructionName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'output',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'invoicing_option',
      name: 'invoicing_option' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_option',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_purge',
      name: 'invoicing_purge' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_purge',
      appReferenceName: 'Invoices',
      type: 9,
    },
    {
      key: 'invoicing_transaction',
      name: 'invoicing_transaction' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_transaction',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'invoicing_transaction_test',
      name: 'invoicing_transaction_test' + ' ('+ 'Invoices' +')',
      referenceName: 'invoicing_transaction_test',
      appReferenceName: 'Invoices',
      type: 9,
    },
    {
      key: 'is_billing_contract_activatable_flow',
      name: 'is_billing_contract_activatable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_activatable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractID',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_contract_copyable_flow',
      name: 'is_billing_contract_copyable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_copyable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractID',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_contract_deactivatable_flow',
      name: 'is_billing_contract_deactivatable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_deactivatable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractID',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_contract_deletable_flow',
      name: 'is_billing_contract_deletable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_deletable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractID',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_contract_line_deletable_flow',
      name: 'is_billing_contract_line_deletable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_line_deletable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_contract_line_valid_flow',
      name: 'is_billing_contract_line_valid_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_contract_line_valid_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingContractId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'billingContractLineIds',
            type: 'number',
            isCollection: true
          },
        ]
    },
    {
      key: 'is_billing_task_cancelable_flow',
      name: 'is_billing_task_cancelable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_task_cancelable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_task_deletable_flow',
      name: 'is_billing_task_deletable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_task_deletable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_billing_task_invoicable_flow',
      name: 'is_billing_task_invoicable_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'is_billing_task_invoicable_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_invoice_cancelable',
      name: 'is_invoice_cancelable' + ' ('+ 'Invoices' +')',
      referenceName: 'is_invoice_cancelable',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_invoice_completable',
      name: 'is_invoice_completable' + ' ('+ 'Invoices' +')',
      referenceName: 'is_invoice_completable',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_invoice_deletable',
      name: 'is_invoice_deletable' + ' ('+ 'Invoices' +')',
      referenceName: 'is_invoice_deletable',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_invoice_exportable',
      name: 'is_invoice_exportable' + ' ('+ 'Invoices' +')',
      referenceName: 'is_invoice_exportable',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'is_invoice_line_deletable',
      name: 'is_invoice_line_deletable' + ' ('+ 'Invoices' +')',
      referenceName: 'is_invoice_line_deletable',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceLineId',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'migrate_storage_to_utilities',
      name: 'migrate_storage_to_utilities' + ' ('+ 'Invoices' +')',
      referenceName: 'migrate_storage_to_utilities',
      appReferenceName: 'Invoices',
      type: 9,
    },
    {
      key: 'submit_entity_import_json_request',
      name: 'submit_entity_import_json_request' + ' ('+ 'Invoices' +')',
      referenceName: 'submit_entity_import_json_request',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'entityImport',
            type: 'object',
            isCollection: false
          },
          {
            required: false,
            id: 'referenceCode',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'project',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouse',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'groupId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transactionGroupId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_billing_task_flow',
      name: 'update_billing_task_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'update_billing_task_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'billingTaskId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'invoiceLineId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'update_invoice_status_flow',
      name: 'update_invoice_status_flow' + ' ('+ 'Invoices' +')',
      referenceName: 'update_invoice_status_flow',
      appReferenceName: 'Invoices',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'invoiceId',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'statusId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'notes',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_billing_aggregation_strategies_dd',
      name: 'ds_billing_aggregation_strategies_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_aggregation_strategies_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_accessorial_charges_template_editor',
      name: 'ds_billing_contract_accessorial_charges_template_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_accessorial_charges_template_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_accessorial_charges_template_lines_grid',
      name: 'ds_billing_contract_accessorial_charges_template_lines_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_accessorial_charges_template_lines_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'templateId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'templateId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'templateId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_accessorial_charges_templates_grid',
      name: 'ds_billing_contract_accessorial_charges_templates_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_accessorial_charges_templates_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'enabled',
              type: 'boolean',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'enabled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'enabled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_auto_renew_types_dd',
      name: 'ds_billing_contract_auto_renew_types_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_auto_renew_types_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_line_details_grid',
      name: 'ds_billing_contract_line_details_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_line_details_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyFieldName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyFieldName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyFieldName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_line_details_validation',
      name: 'ds_billing_contract_line_details_validation' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_line_details_validation',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_lines_count_widget',
      name: 'ds_billing_contract_lines_count_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_lines_count_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_lines_editor',
      name: 'ds_billing_contract_lines_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_lines_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'lineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_lines_grid',
      name: 'ds_billing_contract_lines_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_lines_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'strategyName',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_lines_max_line_number',
      name: 'ds_billing_contract_lines_max_line_number' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_lines_max_line_number',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_lines_validation',
      name: 'ds_billing_contract_lines_validation' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_lines_validation',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'lineIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contract_total_widget',
      name: 'ds_billing_contract_total_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contract_total_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contracts_editor',
      name: 'ds_billing_contracts_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contracts_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'contractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contracts_grid',
      name: 'ds_billing_contracts_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contracts_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'accountId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'endDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_contracts_statuses_dd',
      name: 'ds_billing_contracts_statuses_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_contracts_statuses_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_methods_dd',
      name: 'ds_billing_methods_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_methods_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_outbound_shipment_service_type_methods_dd',
      name: 'ds_billing_outbound_shipment_service_type_methods_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_outbound_shipment_service_type_methods_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_projects_grid',
      name: 'ds_billing_projects_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_projects_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_rates_grid',
      name: 'ds_billing_rates_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_rates_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'accessorialTemplateLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'accessorialTemplateLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'accessorialTemplateLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_record_activity_list',
      name: 'ds_billing_record_activity_list' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_record_activity_list',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'billingTaskId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'billingTaskId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_record_editor',
      name: 'ds_billing_record_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_record_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingTaskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_records_by_invoiceline_grid',
      name: 'ds_billing_records_by_invoiceline_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_records_by_invoiceline_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_records_grid',
      name: 'ds_billing_records_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_records_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'includeCancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: 'includeInvoiced',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'workOrderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingCodeIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'includeCancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: 'includeInvoiced',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'workOrderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'orderIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'includeCancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: true,
              id: 'includeInvoiced',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'workOrderId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingCodeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_records_total_billed_by_current_month_widget',
      name: 'ds_billing_records_total_billed_by_current_month_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_records_total_billed_by_current_month_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'owner_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'project_id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouse_ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'material_ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_records_total_billed_by_date_widget',
      name: 'ds_billing_records_total_billed_by_date_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_records_total_billed_by_date_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_records_total_invoiced_by_date_widget',
      name: 'ds_billing_records_total_invoiced_by_date_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_records_total_invoiced_by_date_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'date',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'materialIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billing_supplemental_fees_grid',
      name: 'ds_billing_supplemental_fees_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billing_supplemental_fees_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_billingcodes_dd',
      name: 'ds_billingcodes_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_billingcodes_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_carrier_service_types_dd',
      name: 'ds_carrier_service_types_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_carrier_service_types_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'serviceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'serviceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'serviceTypeIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_commodity_price_flat_grid',
      name: 'ds_commodity_price_flat_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_commodity_price_flat_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'names',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'names',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_commodity_price_grid',
      name: 'ds_commodity_price_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_commodity_price_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_currencies_dd',
      name: 'ds_currencies_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_currencies_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_diverted_projects_dd',
      name: 'ds_diverted_projects_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_diverted_projects_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_entity_import_requests',
      name: 'ds_entity_import_requests' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_entity_import_requests',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'requestIds',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_include',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'statuses_to_ignore',
              type: 'string',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_find_invoices',
      name: 'ds_find_invoices' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_find_invoices',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'termId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'userName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'date',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'termId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'userName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'termId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'userName',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_active_billing_contracts',
      name: 'ds_get_active_billing_contracts' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_active_billing_contracts',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_contract_by_id',
      name: 'ds_get_billing_contract_by_id' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_contract_by_id',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_contract_line_by_billingContractLineId',
      name: 'ds_get_billing_contract_line_by_billingContractLineId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_contract_line_by_billingContractLineId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_contract_lines_by_billingContractId',
      name: 'ds_get_billing_contract_lines_by_billingContractId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_contract_lines_by_billingContractId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_projects',
      name: 'ds_get_billing_projects' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_projects',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_rates_by_billingContractLineId',
      name: 'ds_get_billing_rates_by_billingContractLineId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_rates_by_billingContractLineId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingContractLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_record_by_billingTaskId',
      name: 'ds_get_billing_record_by_billingTaskId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_record_by_billingTaskId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'billingTaskId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_tasks',
      name: 'ds_get_billing_tasks' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_tasks',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_tasks_by_invoiceLineId',
      name: 'ds_get_billing_tasks_by_invoiceLineId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_tasks_by_invoiceLineId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_tasks_entities',
      name: 'ds_get_billing_tasks_entities' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_tasks_entities',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'object',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billing_tasks_projects',
      name: 'ds_get_billing_tasks_projects' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billing_tasks_projects',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'cancelled',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'shipmentIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'cutOffDate',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'assigned',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'billingAggregationStrategyIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'hasShipment',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'minCreationDate',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'minProjectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_billingrecords_by_invoiceLineId',
      name: 'ds_get_billingrecords_by_invoiceLineId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_billingrecords_by_invoiceLineId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceLineId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_current_billing_contract_by_projectId',
      name: 'ds_get_current_billing_contract_by_projectId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_current_billing_contract_by_projectId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse',
      name: 'ds_get_first_warehouse' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_first_warehouse',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse_address_by_invoiceId',
      name: 'ds_get_first_warehouse_address_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_first_warehouse_address_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_by_invoiceId',
      name: 'ds_get_invoice_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_header_by_invoiceId',
      name: 'ds_get_invoice_header_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_header_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_line_details_by_invoiceId',
      name: 'ds_get_invoice_line_details_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_line_details_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_line_details_by_invoiceId_ne',
      name: 'ds_get_invoice_line_details_by_invoiceId_ne' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_line_details_by_invoiceId_ne',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_lines_grouped_by_invoiceId',
      name: 'ds_get_invoice_lines_grouped_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_lines_grouped_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoice_terms',
      name: 'ds_get_invoice_terms' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoice_terms',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceTermId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoiceline_by_invoiceLineId',
      name: 'ds_get_invoiceline_by_invoiceLineId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoiceline_by_invoiceLineId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoicelines_by_invoiceId_bottom1',
      name: 'ds_get_invoicelines_by_invoiceId_bottom1' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoicelines_by_invoiceId_bottom1',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_invoicestatus_by_statusId',
      name: 'ds_get_invoicestatus_by_statusId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_invoicestatus_by_statusId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'statusId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_last_invoice_line_by_invoiceId',
      name: 'ds_get_last_invoice_line_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_last_invoice_line_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_order_by_orderId',
      name: 'ds_get_order_by_orderId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_order_by_orderId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'orderId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_owner_address_by_invoiceId',
      name: 'ds_get_owner_address_by_invoiceId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_owner_address_by_invoiceId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_project_divert_billing_by_id',
      name: 'ds_get_project_divert_billing_by_id' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_project_divert_billing_by_id',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'id',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId',
      name: 'ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'lookupcode',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_warehouse_address_by_warehouseId',
      name: 'ds_get_warehouse_address_by_warehouseId' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_get_warehouse_address_by_warehouseId',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_editor',
      name: 'ds_invoice_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_lines_grid',
      name: 'ds_invoice_lines_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_lines_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_statuses_dd',
      name: 'ds_invoice_statuses_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_statuses_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludedStatusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'includedStatusIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludedStatusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'includedStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'excludedStatusId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'includedStatusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_terms_dd',
      name: 'ds_invoice_terms_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_terms_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_total_billed_widget',
      name: 'ds_invoice_total_billed_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_total_billed_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_total_billed_with_filters_widget',
      name: 'ds_invoice_total_billed_with_filters_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_total_billed_with_filters_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_total_units_widget',
      name: 'ds_invoice_total_units_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_total_units_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_total_units_with_filters_widget',
      name: 'ds_invoice_total_units_with_filters_widget' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_total_units_with_filters_widget',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoice_types_dd',
      name: 'ds_invoice_types_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoice_types_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoices_dd',
      name: 'ds_invoices_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoices_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'typeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: 'statusId',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoices_grid',
      name: 'ds_invoices_grid' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoices_grid',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'ownerId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'statusIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'createdFrom',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'createdTo',
              type: 'date',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_instructions',
      name: 'ds_invoicing_instructions' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_instructions',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'active',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'active',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_instructions_dd',
      name: 'ds_invoicing_instructions_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_instructions_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_instructions_editor',
      name: 'ds_invoicing_instructions_editor' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_instructions_editor',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'active',
              type: 'boolean',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_logs',
      name: 'ds_invoicing_logs' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_logs',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_options',
      name: 'ds_invoicing_options' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_options',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'exclude',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dimension',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'exclude',
              type: 'string',
              isCollection: true
            },
            {
              required: false,
              id: 'dimension',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_projects_dd',
      name: 'ds_invoicing_projects_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_projects_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'projectIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_invoicing_transactions',
      name: 'ds_invoicing_transactions' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_invoicing_transactions',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'billingContractId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'projectId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'invoiceId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'instructionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transactionId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'requestId',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_location_storage_categories_dd',
      name: 'ds_location_storage_categories_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_location_storage_categories_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_measurement_units_dd',
      name: 'ds_measurement_units_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_measurement_units_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'typeId',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_operation_codes_dd',
      name: 'ds_operation_codes_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_operation_codes_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeId',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeId',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'operationTypeId',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_projects_dd',
      name: 'ds_projects_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_projects_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_reasoncodes_dd',
      name: 'ds_reasoncodes_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_reasoncodes_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'parentId',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: 'parentEntity',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_recurring_storage_billing_objects_dd',
      name: 'ds_recurring_storage_billing_objects_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_recurring_storage_billing_objects_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_recurring_storage_schedule_types_dd',
      name: 'ds_recurring_storage_schedule_types_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_recurring_storage_schedule_types_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'ids',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'idsToExclude',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_renewal_types_dd',
      name: 'ds_renewal_types_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_renewal_types_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_tags_dd',
      name: 'ds_tags_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_tags_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'entityTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'groupIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'tagIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'entityTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'groupIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'tagIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'entityTypeId',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'groupIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'tagIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_tax_schedules_dd',
      name: 'ds_tax_schedules_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_tax_schedules_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_warehouses_dd',
      name: 'ds_warehouses_dd' + ' ('+ 'Invoices' +')',
      referenceName: 'ds_warehouses_dd',
      appReferenceName: 'Invoices',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'id',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: 'fullTextSearch',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'check_footprint_version',
      name: 'check_footprint_version' + ' ('+ 'Utilities' +')',
      referenceName: 'check_footprint_version',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'minimumVersion',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations',
      name: 'configurations' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_delete',
      name: 'configurations_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_distinct',
      name: 'configurations_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_get',
      name: 'configurations_get' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_get_one',
      name: 'configurations_get_one' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_get_one',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_purge',
      name: 'configurations_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_update',
      name: 'configurations_update' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'configurations_write',
      name: 'configurations_write' + ' ('+ 'Utilities' +')',
      referenceName: 'configurations_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_dates_between_timezones_flow',
      name: 'convert_dates_between_timezones_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_dates_between_timezones_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseIds',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'convertDates',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'isFromUtcToWarehouse',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_distance_flow',
      name: 'convert_distance_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_distance_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputDistance',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_volume_flow',
      name: 'convert_volume_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_volume_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputVolume',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'convert_weight_flow',
      name: 'convert_weight_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'convert_weight_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputWeight',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'fromUom',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'toUom',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_batch_request_flow',
      name: 'crud_batch_request_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_batch_request_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entities',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'withTransaction',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_create_flow',
      name: 'crud_create_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_create_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_dual_pk_flow',
      name: 'crud_delete_dual_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_dual_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_dual_pk_flow_string',
      name: 'crud_delete_dual_pk_flow_string' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_dual_pk_flow_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_flow',
      name: 'crud_delete_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_quad_pk_flow',
      name: 'crud_delete_quad_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_quad_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_delete_triple_pk_flow',
      name: 'crud_delete_triple_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_delete_triple_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_dual_pk_flow',
      name: 'crud_update_dual_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_dual_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_dual_pk_flow_string',
      name: 'crud_update_dual_pk_flow_string' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_dual_pk_flow_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_flow',
      name: 'crud_update_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'id',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_quad_pk_flow',
      name: 'crud_update_quad_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_quad_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk4',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'crud_update_triple_pk_flow',
      name: 'crud_update_triple_pk_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'crud_update_triple_pk_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entitySet',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'entity',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk1',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk2',
            type: 'number',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3String',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'pk3',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'date_get_from_footprint',
      name: 'date_get_from_footprint' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_from_footprint',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'date_get_utc_flow',
      name: 'date_get_utc_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_utc_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'warehouseDate',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'date_get_warehouse_flow',
      name: 'date_get_warehouse_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'date_get_warehouse_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'warehouseId',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'utcDate',
            type: 'date',
            isCollection: false
          },
        ]
    },
    {
      key: 'flatten_object_property',
      name: 'flatten_object_property' + ' ('+ 'Utilities' +')',
      referenceName: 'flatten_object_property',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'flattenArray',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'childName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'flattenValueName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'returnReversed',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'format_username_flow',
      name: 'format_username_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'format_username_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inUserName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'get_username_flow',
      name: 'get_username_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'get_username_flow',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'grammar_format_string_array_flow',
      name: 'grammar_format_string_array_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'grammar_format_string_array_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'values',
            type: 'string',
            isCollection: true
          },
          {
            required: false,
            id: 'delimiter',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'finalValueDelimiter',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_applications',
      name: 'list_applications' + ' ('+ 'Utilities' +')',
      referenceName: 'list_applications',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_processes',
      name: 'list_processes' + ' ('+ 'Utilities' +')',
      referenceName: 'list_processes',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'process_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_statuses',
      name: 'list_statuses' + ' ('+ 'Utilities' +')',
      referenceName: 'list_statuses',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'status_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'list_transaction_types',
      name: 'list_transaction_types' + ' ('+ 'Utilities' +')',
      referenceName: 'list_transaction_types',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_type',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'action',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs',
      name: 'logs' + ' ('+ 'Utilities' +')',
      referenceName: 'logs',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_clean',
      name: 'logs_clean' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_clean',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_delete',
      name: 'logs_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_distinct',
      name: 'logs_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_get',
      name: 'logs_get' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_purge',
      name: 'logs_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_update',
      name: 'logs_update' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'logs_write',
      name: 'logs_write' + ' ('+ 'Utilities' +')',
      referenceName: 'logs_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'math_round_flow',
      name: 'math_round_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'math_round_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'inputNumber',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'position',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages',
      name: 'messages' + ' ('+ 'Utilities' +')',
      referenceName: 'messages',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'payload',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'action',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'skip',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'take',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'date',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'application_name',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'full_text_search',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_clean',
      name: 'messages_clean' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_clean',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_delete',
      name: 'messages_delete' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_delete',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_distinct',
      name: 'messages_distinct' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_distinct',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
          {
            required: true,
            id: 'field',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'top',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_get',
      name: 'messages_get' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_get',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_next',
      name: 'messages_next' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_next',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_purge',
      name: 'messages_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_purge',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_purge_string',
      name: 'messages_purge_string' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_purge_string',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_update',
      name: 'messages_update' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_update',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'messages_write',
      name: 'messages_write' + ' ('+ 'Utilities' +')',
      referenceName: 'messages_write',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'input',
            type: 'object',
            isCollection: true
          },
          {
            required: true,
            id: 'criteria',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'odata_encode_special_characters_for_url',
      name: 'odata_encode_special_characters_for_url' + ' ('+ 'Utilities' +')',
      referenceName: 'odata_encode_special_characters_for_url',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'stringToEncode',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'reserve_batchId_flow',
      name: 'reserve_batchId_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'reserve_batchId_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'batchSize',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'reserve_nextId_flow',
      name: 'reserve_nextId_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'reserve_nextId_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'entity',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'storage_populate_date_numer',
      name: 'storage_populate_date_numer' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_populate_date_numer',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'storage_purge',
      name: 'storage_purge' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_purge',
      appReferenceName: 'Utilities',
      type: 9,
    },
    {
      key: 'storage_test',
      name: 'storage_test' + ' ('+ 'Utilities' +')',
      referenceName: 'storage_test',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'rounds',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'messages',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'purge',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'string_encrypt',
      name: 'string_encrypt' + ' ('+ 'Utilities' +')',
      referenceName: 'string_encrypt',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'text',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'encrypted',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'upload_file_to_blob_storage_flow',
      name: 'upload_file_to_blob_storage_flow' + ' ('+ 'Utilities' +')',
      referenceName: 'upload_file_to_blob_storage_flow',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'storageAccount',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'signature',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'container',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'fileContent',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'fileName',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'validate_component_required_fields',
      name: 'validate_component_required_fields' + ' ('+ 'Utilities' +')',
      referenceName: 'validate_component_required_fields',
      appReferenceName: 'Utilities',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'componentType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'component',
            type: 'object',
            isCollection: false
          },
        ]
    },
    {
      key: 'ds_applications_dd',
      name: 'ds_applications_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_applications_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_configurations_grid',
      name: 'ds_configurations_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_configurations_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'user_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_get_first_warehouse',
      name: 'ds_get_first_warehouse' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_get_first_warehouse',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
        },
      ]
    },
    {
      key: 'ds_get_warehouses_by_warehouseIds',
      name: 'ds_get_warehouses_by_warehouseIds' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_get_warehouses_by_warehouseIds',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'get',
          name: 'get',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
          ]
        },
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: true,
              id: 'warehouseIds',
              type: 'number',
              isCollection: true
            },
            {
              required: true,
              id: '$keys',
              type: 'number',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_logs_grid',
      name: 'ds_logs_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_logs_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: true,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'level',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_messages_grid',
      name: 'ds_messages_grid' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_messages_grid',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'date_from',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'date_to',
              type: 'date',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'process_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_id',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'status_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'reference',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'transaction_type',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'direction',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'environment',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_1',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_2',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_3',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_4',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'string_5',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'number_1',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_2',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_3',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_4',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: 'number_5',
              type: 'number',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_processes_dd',
      name: 'ds_processes_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_processes_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_statuses_dd',
      name: 'ds_statuses_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_statuses_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
    {
      key: 'ds_transaction_types_dd',
      name: 'ds_transaction_types_dd' + ' ('+ 'Utilities' +')',
      referenceName: 'ds_transaction_types_dd',
      appReferenceName: 'Utilities',
      type: 6,
      methods: [
        {
          key: 'getList',
          name: 'getList',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: '$top',
              type: 'number',
              isCollection: false
            },
            {
              required: false,
              id: '$skip',
              type: 'number',
              isCollection: false
            },
          ]
        },
        {
          key: 'getByKeys',
          name: 'getByKeys',
          inParams: [
            {
              required: false,
              id: 'application_name',
              type: 'string',
              isCollection: false
            },
            {
              required: false,
              id: 'full_text_search',
              type: 'string',
              isCollection: false
            },
            {
              required: true,
              id: '$keys',
              type: 'string',
              isCollection: true
            },
          ]
        },
      ]
    },
  ];

  async configDisplayWithFn(value: any): Promise<string> {
    return this.configList.find(item => item.key === value)?.name;
  }

  async configOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.configList.filter(item => item.type === this.typeValue);
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  configValueChange(value) {
    this.configValue = value;
    this.config = this.configList.find(item => item.key === value);
    this.methodValueChange(null);
    this.dd_method.clearListData();
  }

  get hasMethods():boolean {
    return this.config && this.config?.methods;
  }
  
  method = null;
  methodValue = null;

  async methodDisplayWithFn(value: any): Promise<string> {
    return value;
  }

  async methodOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.config.methods;
    const totalCount = list.length;
    if (this.utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  methodValueChange(value) {
    this.methodValue = value;
    this.result = null;    
    if (this.hasMethods) {
      this.method = this.config.methods.find(item => item.key === value);
    } else {
      this.method = null;
    }
    this.onInParamChanged();
  }


  get inParams() {
    if (this.config) {
      if (this.config.methods) {
        if (this.method) {
          return this.method.inParams;
        }
      } else {
        return this.config.inParams;
      }
    }
    return null;
  }

  previewInParams = null;
  onInParamChanged() {
    this.previewInParams = {};
    if (this.inParams && this.inParams.length) {
      const inParams: { [key: string]: any } = {};
      this.inParams.forEach(ip => {
        inParams[ip.id] = this.evaluateValue(ip.value);
      });
      this.previewInParams = inParams;
    }
  }

  evaluateValue(value: string) {
    const val = this.utils.isDefinedTrimmed(value) ? `(${value})` : value;
    const f = new Function(`{ return ${val} }`);
    return f();
  }

  get canExecute (): boolean {
    if (!this.config) {
      return false;
    }
    if (this.config.type === 6 && !this.methodValue) {
      return false;
    } 
    return true;
  }

  result: any;
  async run() {
    if (this.config.type === 6) {
      this.result = await this.datasources[this.config.appReferenceName][this.config.referenceName][this.methodValue](this.previewInParams);
    } else if (this.config.type === 9) {
      this.result = await this.flows[this.config.appReferenceName][this.config.referenceName](this.previewInParams);
    }
  }  
}
